import Vue from 'vue'
import VueRouter from 'vue-router'
import { Toast } from 'mint-ui'
import Login from '@/views/Login.vue'
import Upload from '@/views/Upload.vue'
import Commit from '@/views/Commit.vue'
import Result from '@/views/Result.vue'

Vue.use(VueRouter)

function lazyLoad(view: string) {
  return import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login,
    meta: {
      deepIndex: 1, // 页面深度，用于判断过渡效果（左滑/右滑）
      isNeedToken: false
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload,
    meta: {
      deepIndex: 2,
      isNeedToken: true
    }
  },
  {
    path: '/commit',
    name: 'commit',
    component: Commit,
    meta: {
      deepIndex: 3,
      isNeedToken: true
    }
  },
  {
    path: '/result',
    name: 'result',
    component: Result,
    meta: {
      deepIndex: 4,
      isNeedToken: true
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const token:string | null = localStorage.getItem('token')
  const expireDate:string | null = localStorage.getItem('expireDate')
  const a:string | null = localStorage.getItem('a')
  const b:string | null = localStorage.getItem('b')
  const c:string | null = localStorage.getItem('c')

  if (Object.keys(to.meta).length) {
    if (to.meta.isNeedToken) {
      if (token && expireDate && Number(expireDate) > new Date().valueOf()) {
        next()
      } else {
        if (a && b && c) {
          await Toast('登录信息失效，请重新登录')
          localStorage.removeItem('token')
          localStorage.removeItem('expireDate')
          localStorage.removeItem('id')
          await next({ path: '/', name: 'login', query: { a, b, c }, replace: true})
        } else {
          await Toast('该页面已过期，请重新打开链接')
          await next({ path: '/', name: 'login', replace: true})
        }
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
