





















































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Indicator  } from 'mint-ui'
import { ooxx, checkPhone } from '../utils/index'
import { fetchUserInfo, fetchRegister, fetchContractCode, fetchContractCheck, fetchContractFaceUrl } from '../request/api'
import iconChecked from '../assets/images/icon_selete@2x.png'
import iconTip from '../assets/images/icon_notice@3x.png'
import iconSelected from '../assets/images/login_check@2x.png'
import iconUnSelected from '../assets/images/login_check_box@2x.png'

@Component
export default class Commit extends Vue {
  iconChecked = iconChecked
  iconTip = iconTip
  iconSelected = iconSelected
  iconUnSelected = iconUnSelected
  waitingTime: number = 0
  seconds: number = 60
  loginCode:string = ''
  cardCode:string = ''
  bankCardNum:string = ''
  bankCardPhoneNum:string = ''
  timer: any = null
  popupVisible1:boolean = false
  tips: string = ''
  tipTimer: any = null  // 提示定时器
  idInfo = {
    name: '',
    idNumber: ''
  }
  typeList: object[] = [
    { id: 1, txt: '手机号认证', tag: 'PHONE_NUMBER' },
    { id: 2, txt: '银行卡认证', tag: 'BANK_CARD' },
    { id: 3, txt: '刷脸认证', tag: 'FACE' }
  ]
  currentCheckdTag: string = 'PHONE_NUMBER'
  phoneNumber: string = ''
  agreeFlag:boolean = false

  async created() {
    await this.getUserInfo()
    await this.register(this.idInfo.name, this.idInfo.idNumber)
  }

  get codeAlreadyFlag(): boolean {
    return this.waitingTime <= 0
  }

  get btnDisabledFlag(): boolean {
    if (this.currentCheckdTag === 'PHONE_NUMBER') {
      return !checkPhone(this.phoneNumber) || !this.loginCode || !this.agreeFlag
    } else {
      return !this.bankCardPhoneNum || !this.bankCardNum || !this.cardCode
    }
  }

  // 获取身份信息
  async getUserInfo() {
    const params = {
      contractId: localStorage.getItem('a') || '',
      type: localStorage.getItem('b') || ''
    }
    const res:any = await fetchUserInfo(params)
    if (res.code === 1) {
      this.idInfo.name = res.data.name
      this.idInfo.idNumber = res.data.idCard
    }
  }

  // 身份信息注册
  async register(name: string, identity: string) {
    const data = {
      name,
      credential: { identity }
    }
    const res:any = await fetchRegister(data)
    if (res.code === 1) {
      localStorage.setItem('id', res.data)
    }
  }

  // 发送短信码
  async sendCode() {
    const data = {
      name: this.idInfo.name,
      identity: this.idInfo.idNumber,
      mobile: this.phoneNumber
    }
    const res:any = await fetchContractCode(data)
    if (res.code !== 1) {
      await this.showTips(`发送失败，${res.message}`)
    } else {
      this.waitingTime = this.seconds
      this.countdown(this.seconds)
    }
  }

  async next() {
    await this.check(this.loginCode)
  }

  // 校验
  async check(code: string) {
    const data:any = {
      name: 'result'
    }
    await fetchContractCheck({ code })
    await this.$router.push(data)
  }

  // 获取验证码按钮
  async getCode() {
    if (!checkPhone(this.phoneNumber)) return await this.showTips('输入正确的手机号')
    await this.sendCode()
  }

  // 倒计时
  countdown(seconds: number):void {
    if (seconds >= 0) {
      this.timer = setInterval(() => {
        if (seconds === 0) {
          this.timer = null
          clearInterval(this.timer)
        } else {
          seconds--
          this.waitingTime = seconds
        }
      }, 1000)
    }
  }

  // 打开刷脸
  async openFaceCheckUrl() {
    const data = {
      name: this.idInfo.name,
      identity: this.idInfo.idNumber
    }
    const res:any = await fetchContractFaceUrl(data)
    if (res.code === 1) {
      window.location.href = res.data.url
    }
  }

  // 出错提醒
  async showTips(tipTxt: string) {
    if (this.popupVisible1 && tipTxt === this.tips) return
    clearTimeout(this.tipTimer)
    this.popupVisible1 = true
    this.tips = tipTxt
    this.tipTimer = setTimeout(() => {
      this.popupVisible1 = false
    }, 1500)
  }
}

