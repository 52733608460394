



































import { Component, Prop, Vue } from "vue-property-decorator";
import { Indicator } from "mint-ui";
import {
  fetchPersonalCheckState,
  fetchContractUrl,
  fetchContractState,
  fetchContractPreview,
} from "../request/api";
import { ooxx } from "../utils/index";
import iconSuccess from "../assets/images/icon_success@2x.png";
import iconFail from "../assets/images/icon_fail@2x.png";

@Component
export default class Result extends Vue {
  iconSuccess = iconSuccess;
  iconFail = iconFail;
  popupVisible1: boolean = false;
  successedFlag: boolean = false;
  resultTxt: string[] = ["认证通过", "认证失败"];
  tipsTxt: string[] = [
    "上上签正在调用您的数字证书，您在安全签约环境中，请放心签署！",
    "您提交个人实名认证未通过，请重新提交认证信息或选择其他认证方式",
  ];
  reloadText: String[] = ["为您自动跳转到合同签署页面...", ""];
  timer: any = null;
  timer1: any = null;
  showRes: boolean = false;
  tips: string = ""; // 提示文字
  tipTimer: any = null; // 提示定时器

  async created() {
    const query: any = this.$route.query;
    const params: any = this.$route.query;

    Indicator.open();
    clearTimeout(this.timer);
    // this.timer = setTimeout(async () => {
    await this.getAuthState();
    // }, 3000)
  }

  mounted() {}

  async back() {
    await this.$router.replace({ name: "commit" });
  }

  // 查询实名状态
  async getAuthState() {
    const res: any = await fetchPersonalCheckState();
    if (res.code === 1) {
      if (res.data === 5) {
        // 申请成功
        Indicator.close();
        this.showRes = true;
        this.successedFlag = true;
        setTimeout(async () => {
          await this.getContractUrl();
        }, 3000);
      } else if (res.data === 2) {
        // 申请中
        clearTimeout(this.timer1);
        this.timer1 = setTimeout(async () => {
          return await this.getAuthState();
        }, 1000);
      } else {
        Indicator.close();
        this.showRes = true;
        this.successedFlag = false;
      }
    }
  }

  // 查询合同状态
  async getContractState() {
    const res: any = await fetchContractState(localStorage.getItem("a") || "");
    if (res.code === 1) return res.data;
  }

  // 获取合同链接
  async getContractUrl() {
    const res1: any = await this.getContractState();
    if (res1 === 2 || res1 === 3) {
      const data = {
        contractId: localStorage.getItem("a"),
        account: localStorage.getItem("account"),
        type: localStorage.getItem("b"),
      };
      const res: any = await fetchContractUrl(data);
      if (res.code === 1) {
        window.location.href = res.data.shortUrl;
      }
    } else if (res1 === 5 || res1 === 4) {
      // 已完成
      const res: any = await fetchContractPreview(
        localStorage.getItem("a") || ""
      );
      if (res.code === 1) {
        window.open(res.data);
      }
    } else {
      await this.showTips("文件过期，无法预览");
    }
  }

  // 出错提醒
  async showTips(tipTxt: string) {
    if (this.popupVisible1 && tipTxt === this.tips) return;
    clearTimeout(this.tipTimer);
    this.popupVisible1 = true;
    this.tips = tipTxt;
    this.tipTimer = setTimeout(() => {
      this.popupVisible1 = false;
    }, 1500);
  }
}
