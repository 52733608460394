import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Button, Header, Popup } from 'mint-ui'
import 'mint-ui/lib/style.css'
import './assets/styles/mixins.less'

Vue.config.productionTip = false
Vue.component(Button.name, Button)
Vue.component(Header.name, Header)
Vue.component(Popup.name, Popup)


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
