import ajax from './http.js'

// 获取图片验证码
export function fetchImgCode() {
  const now = new Date()
  return ajax.post(`portal/captcha/image?${now}`)
}

// 校验图片验证码
export function fetchCheckImgCode(data:object) {
  return ajax.post(`portal/mobile/verificationCode`, data)
}

// 登录
export function fetchLogin(data:object) {
  return ajax.post(`contract/personal/user/login`, data)
}

// 获取身份信息
export function fetchUserInfo(params:object) {
  return ajax.get(`contract/contract/findInfoByContractIdAndType`, { params })
}

// 身份信息注册
export function fetchRegister(data:object) {
  return ajax.post(`contract/personal/user/without/cert`, data)
}

// 查询个人实名状态
export function fetchPersonalCheckState() {
  return ajax.get(`contract/personal/user/realName/verify/flag`)
}

// 获取合同签署状态
export function fetchContractState(contractId: string) {
  return ajax.get(`contract/contract/findStateByContractId?contractId=${contractId}`)
}

// 获取合同签署链接
export function fetchContractUrl(data:object) {
  return ajax.post(`contract/contract/manualSign`, data)
}

// 获取合同预览链接
export function fetchContractPreview(id:string) {
  return ajax.get(`contract/contract/previewContract?contractId=${id}`)
}

// 实名认证-获取手机码
export function fetchContractCode(data:object) {
  return ajax.post(`contract/personal/user/realName/code/sender/mobile`, data)
}

// 实名认证-手机号校验
export function fetchContractCheck(data:object) {
  return ajax.post(`contract/personal/user/realName/code/verify/mobile`, data)
}

// 实名认证-刷脸url
export function fetchContractFaceUrl(data:object) {
  return ajax.post(`contract/personal/user/faceVerifyUrl`, data)
}