










import { Component, Watch, Vue } from 'vue-property-decorator'

@Component
export default class App extends Vue{
  tranName: string = 'fold-left'

  // 设置过渡效果
  @Watch('$route')
  routeChange(next: any, pre: any) {
    const nextDeepIndex = next.meta.deepIndex
    const preDeepIndex = pre.meta.deepIndex
    if (preDeepIndex && nextDeepIndex) {
      this.tranName = nextDeepIndex >= preDeepIndex ? 'fold-left' : 'fold-right'
    } else {
      this.tranName = 'fold-left'
    }
  } 
}

