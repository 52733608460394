
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ooxx, checkPhone } from '../utils/index'
import { Indicator } from 'mint-ui'
import { fetchImgCode, fetchCheckImgCode, fetchUserInfo, fetchLogin, fetchPersonalCheckState, fetchContractUrl } from '../request/api'
import imgLogo from '../assets/images/icon_logo@2x.png'
import iconPhone from '../assets/images/icon_phone@3x.png'
import iconCode from '../assets/images/icon_pic_code@2x.png'
import iconKey from '../assets/images/icon_code@3x.png'

@Component({})
export default class Login extends Vue {
  imgLogo = imgLogo
  iconPhone = iconPhone
  iconKey = iconKey
  iconCode = iconCode
  seconds: number = 60 // 短信冷却时间
  waitingTime: number = 0 // 自减秒数
  phoneNumber:string = ''
  loginCode:string = ''  // 短信码
  timer: any = null  // 定时器
  imgCodeUrl: string = ''  // 图片码url
  popupVisible: boolean = false
  imageId: string = '' 
  loginImgCode: string = '' // 图片码
  tips: string = ''   // 提示文字
  tipTimer: any = null  // 提示定时器
  popupVisible1: boolean = false
  stopBtn: boolean = false

  async created() {
    await this.checkUrl(this.$route.query)
    localStorage.removeItem('token')
    localStorage.removeItem('expireDate')
    await this.getImgCode()
  }

  get codeAlreadyFlag(): boolean {
    return this.waitingTime <= 0
  }

  get btnLoginDisabledFlag(): boolean {
    return this.stopBtn || !this.phoneNumber || !this.loginCode || !this.loginImgCode
  }

  // 路径参数检验
  async checkUrl(obj: any) {
    if (Object.keys(obj).length === 3 && typeof obj.a === 'string' && typeof obj.b === 'string' && typeof obj.c === 'string') {
      this.phoneNumber = ooxx(obj.c)
      localStorage.setItem('a', obj.a)
      localStorage.setItem('b', obj.b)
      localStorage.setItem('c', obj.c)
    } else {
      this.popupVisible = true
    }
  }

  // 获取短信验证码
  async getCode() {
    if (!checkPhone(this.phoneNumber) || !this.loginImgCode) return await this.showTips('请填写图片验证码')
    const res:any = await this.checkImgCode()
    if (res.code === 1) {
      this.waitingTime = this.seconds
      this.countdown(this.seconds)
    } else {
      this.loginCode = ''
      this.loginImgCode = ''
      await this.getImgCode()
      return await this.showTips(res.message)
    }
  }

  // 校验图片验证码
  async checkImgCode() {
    const data = {
      imageId: this.imageId,
      verifyCode: this.loginImgCode,
      mobile: this.phoneNumber
    }
    return await fetchCheckImgCode(data)
  }


  // 出错提醒
  async showTips(tipTxt: string) {
    if (this.popupVisible1 && tipTxt === this.tips) return
    clearTimeout(this.tipTimer)
    this.popupVisible1 = true
    this.tips = tipTxt
    this.tipTimer = setTimeout(() => {
      this.popupVisible1 = false
    }, 1500)
  }

  // 获取图片验证码
  async getImgCode() {
    try {
      const { data = {} } = await fetchImgCode()
      this.imageId = data.imageId
      this.imgCodeUrl = data.content
    } catch(e) {
    }
  }

  // 倒计时
  countdown(seconds: number):void {
    if (seconds >= 0) {
      this.timer = setInterval(() => {
        if (seconds === 0) {
          this.timer = null
          clearInterval(this.timer)
        } else {
          seconds--
          this.waitingTime = seconds
        }
      }, 1000)
    }
  }

  // 登录按钮
  async login() {
    this.stopBtn = true
    await this.getToken()
    if (!await this.getToken()) return this.stopBtn = false
    const res:any = await this.getAuthState()
    console.log('认证状态', res)
    if (res.code == 1) {
      if (res.data === 1) {
        // 新申请
        await this.$router.replace({ name: 'commit' })
      } else {
        await this.$router.replace({ name: 'result' })
      }
    } else if (res.code === -1) {
      console.log('未注册')
      // 新用户未注册
      await this.$router.replace({ name: 'commit' })
    } else {}
    this.stopBtn = false
  }

  // token存储
  async getToken() {
    const data = {
      mobile: this.phoneNumber,
      captcha: this.loginCode
    }
    const res:any = await fetchLogin(data)
    if (res.code === 1) {
      localStorage.setItem('token', res.data.jwt.token)
      localStorage.setItem('expireDate', `${new Date().valueOf() + 30 * 60 * 1000}`) // 产品需求： 登录有效期半个小时
      localStorage.setItem('account', res.data.account)
      return true
    } else {
      await this.showTips(res.message)
      return false
    }
  }

  // 查询实名状态
  async getAuthState() {
    return await fetchPersonalCheckState()
  }

  // 获取合同链接
  async getContractUrl() {
    const data = {
      contractId: localStorage.getItem('a'),
      account: localStorage.getItem('account'),
      type: localStorage.getItem('b')
    }
    const res:any =  await fetchContractUrl(data)
    if (res.code === 1) {
      window.location.href = res.data.shortUrl
    }
  }
}

