



































import { Component, Prop, Vue } from 'vue-property-decorator'
import iconTip from '../assets/images/icon_notice@3x.png'

interface IDInfo {
  name: string
  idNumber: string
}

@Component
export default class Upload extends Vue {
  preIdCardImg: any = null
  iconTip:any = iconTip
  idInfo: IDInfo = {
    name: '张三',
    idNumber: '312311546461313'
  }

  get btnDisabledFlag(): boolean {
    return !this.preIdCardImg || !Boolean(this.idInfo.name) || !Boolean(this.idInfo.idNumber)
  }

  async getFile(e: any) {
    if (!Object.keys(e.target.files).length) return
    const file = e.target.files[0],
      reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (e: any) => this.preIdCardImg = e.target.result
  }
  trimInput(key: number, e: any) {
    console.log(e)
    if (e.target.value) {
      if (key === 0) {
        this.idInfo.name = e.target.value.trim()
      }
      if (key === 1) {
        this.idInfo.idNumber = e.target.value.trim()
      }
    }
  }

  async next() {
    await this.$router.push({ name: 'commit' })
  }
}

