export function ooxx(number_code: any) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
    radix = chars.length,
    len = number_code.length,
    i = 0,
    origin_number = 0;
  while (i < len) {
    origin_number += Math.pow(radix, i++) * chars.indexOf(number_code.charAt(len - i) || 0);
  }
  return String(origin_number)
}

export function checkPhone(value: string): boolean{ 
  return (/^1[3456789]\d{9}$/).test(value)
}