import axios from 'axios'
import baseURL from '../../dev'
const PLATFORM = 'saas-contract-h5'

// console.log('process.env.VUE_APP_BASE_API', process.env.VUE_APP_BASE_API)
// console.log('baseURL', baseURL)
const service = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? (process.env.VUE_APP_BASE_API).split('"')[1] : baseURL,
  timeout: 100000
})

// request拦截器
service.interceptors.request.use(async config => {
  const now = Date.parse(new Date())
  const token = localStorage.getItem('token') || false

  config.headers['Content-Type'] =  'application/json; charset=UTF-8' //
  config.headers['jwt'] = token || ''
  config.headers['Platform'] = PLATFORM
  return config
}, error => {
  console.log(error) // for debug
  Promise.reject(error)
})


// respone拦截器
service.interceptors.response.use(res => {
  return res.data
})

export default service
 